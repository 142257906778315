@font-face {
  font-family:"europa";
  src:url("../public/fonts/europa_bold.woff") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
  font-stretch:normal;
}

@font-face {
  font-family:"europa";
  src:url("../public/fonts/europa.woff") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
  font-stretch:normal;
}

$font-family-yu: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
$font-family-bask: BaskOldFace, serif;
$font-family-diot: 'Didot', serif;
$font-family-helvetica: Helvetica Neue;
$font-family-europa: europa, sans-serif;
$font-family-europa-yu: europa, 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
$font-family-brandon: "Brandon Grotesque", sans-serif;

@mixin use-yu-gothic {
  font-family: $font-family-yu;
}

@mixin use-brandon {
  font-family: $font-family-brandon;
}

@mixin use-bask {
  // Bask was replaced with europa
  // We will deprecate this mixin
  font-family: $font-family-europa;
}

@mixin use-europa {
  font-family: $font-family-europa;
}

@mixin use-europa-yu-gothic {
  font-family: $font-family-europa-yu;
}

@mixin type-title-xxl {
  @include desktop {
    font-size: 40px;
    line-height: 56px;
  }
  @include tablet {
    font-size: 40px;
    line-height: 56px;
  }
  @include sp {
    font-size: mob(40);
    line-height: mob(56);
  }
}

@mixin type-title-xl {
  @include desktop {
    font-size: 32px;
    line-height: 40px;
  }
  @include tablet {
    font-size: 32px;
    line-height: 40px;
  }
  @include sp {
    font-size: mob(32);
    line-height: mob(40);
  }
}

@mixin type-title-l {
  @include desktop {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
  @include tablet {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
  @include sp {
    font-size: mob(24);
    line-height: mob(32);
    letter-spacing: mob(2);
  }
}

@mixin type-title-m {
  @include desktop {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
  @include tablet {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
  @include sp {
    font-size: mob(20);
    line-height: mob(28);
    letter-spacing: mob(2);
  }
}

@mixin type-title-s {
  @include desktop {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
  @include tablet {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
  @include sp {
    font-size: mob(16);
    line-height: mob(24);
    letter-spacing: mob(2);
  }
}

@mixin type-title-base($font-size) {
  @include desktop {
    font-size: #{$font-size}px;
    line-height: #{$font-size * 1.4}px;
  }
  @include tablet {
    font-size: #{$font-size}px;
    line-height: #{$font-size * 1.4}px;
  }
  @include sp {
    font-size: mob($font-size);
    line-height: mob($font-size * 1.4);
  }
}


@mixin type-text-l {
  @include desktop {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.6px;
  }
  @include tablet {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.6px;
  }
  @include sp {
    font-size: mob(16);
    line-height: mob(32);
    letter-spacing: mob(1.6);
  }
}

@mixin type-text-custom($fontSize) {
  @include desktop {
    font-size: #{$fontSize}px;
    line-height: 2;
    letter-spacing: 0.16em;
  }
  @include tablet {
    font-size: #{$fontSize}px;
    line-height: 2;
    letter-spacing: 0.16em;
  }
  @include sp {
    font-size: mob($fontSize);
    line-height: 2;
    letter-spacing: 0.16em;
  }
}


@mixin type-text {
  @include desktop {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  @include tablet {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  @include sp {
    font-size: mob(14);
    line-height: mob(28);
    letter-spacing: mob(1.6);
  }
}

@mixin type-label-text {
  @include desktop {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  @include tablet {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  @include sp {
    font-size: mob(12);
    line-height: mob(20);
    letter-spacing: mob(1.6);
  }
}

@mixin type-tiny-text {
  @include desktop {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
  @include tablet {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
  @include sp {
    font-size: mob(10);
    line-height: mob(14);
    letter-spacing: mob(1.6);
  }
}

@mixin raw-html-content {
  strong {
    font-weight: bold;
  }

  em {
    font-style: oblique;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    margin-left: .5em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: .5em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  a {
    color: $primary;
    text-decoration: underline;
  }
}
