:root {
  --font-family-text: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  --font-family-europa-yu: europa, 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;

  --primary: #4C6072;
  --pink-1: #D6A0A0;
  --pink-m1: #F9F0F1;
  --pink-darken: #C38383;
  --pink-light: #FBF7F7;
  --pink-lightest: #F2E5E5;
  --green-1: #3C676E;
  --blue-1: #6B9AAA;
  --blue-m1: #EEF6F8;
  --blue: #6B9BAA;

  --notify-red: #DF5475;
  --multi-creator-lp-red: #99293D;

  --text-default: #1F2D32;
  --text-black: #1F2C32;
  --text-gray-3: #7a7785;
  --text-gray-2: #AFB0B5;
  --text-gray-1: #D3D4D8;
  --text-gray-0: #E8ECEF;
  --text-white: #FFFFFF;
  --text-blue-1: #2b8ade;

  --input-border: #E2E4E4;

  --button-secoundary-border: #C7CBCC;
  --button-secoundary-text: #C7CBCC;
  --button-secoundary: #C7CBCC;

  --bg-gray-0: #F3F5F5;
  --border-gray-0: #DADBDD;
  --border-gray-m1: #FBFBFC;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-text);
  color: var(--text-default);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
