// Breakpoints for the grid
$grid-breakpoints: (
        mobile: 769px,
        sp: 450px
);

//----------------------------------------------------
//  Base functions & mixins
//----------------------------------------------------

// Will return breakpoint in breakpoints map
@function get-breakpoint($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @return map-get($grid-breakpoints, $breakpoint);
  } @else {
    @warn 'Could not find #{$breakpoint} in $grid-breakpoints map. Please make sure it is defined';
  }
}

// Allows us to use a mixin for media queries rather than writing the media query and including the function
@mixin max-width($breakpoint) {
  @media only screen and (max-width: get-breakpoint($breakpoint)) {
    @content
  }
}

@mixin min-width($breakpoint) {
  @media only screen and (min-width: get-breakpoint($breakpoint)) {
    @content
  }
}

@mixin mobile {
  @include max-width(mobile) {
    @content
  }
}

@mixin sp {
  @include max-width(sp) {
    @content
  }
}

@mixin non-sp {
  @include min-width(sp) {
    @content
  }
}

@mixin tablet {
  @include min-width(sp) {
    @include max-width(mobile) {
      @content
    }
  }
}

@mixin desktop {
  @include min-width(mobile) {
    @content;
  }
}


// calculates relative mobile size based on the size on screen width : 750px
@function mobileSize($originalWidth) {
  @return calc(#{calc(100 * $originalWidth/ 750)}vw)
}

// default translation of desktop designs to mobile
@function desktopToMobile($desktopSize) {
  @return mobileSize($desktopSize * 2);
}

// calculates relative mobile size based on the size on screen width : 375px
@function mob($originalWidth) {
  @return calc(#{calc(100 * $originalWidth/ 375)}vw)
}

@function mob-old($originalWidth) {
  @return calc(#{calc(100 * $originalWidth/ 320)}vw)
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



