@import '../../styles/common.scss';

.container {
    padding: 30px 0;
    background-color: #34414E;
    color: #fff;
    text-align: center;
    font-family: var(--font-family-text);
    font-size: 12px;
    @include mobile {
        padding: mobileSize(82) mobileSize(41);
        .container {
            width: 100%;
        }
    }
}

.container a {
    text-decoration: none;
    color: white;
}

.container a:hover {
     opacity: 0.7;
}


.section {
    margin: auto;
    width: 66.7%;
    text-align: left;
    @include mobile {
        width: 100%;
    }
}

.link {
    text-align: left;
    margin-bottom: 4px;
}

.link a {
    font-size: 12px;
}

.linkTitle {
    font-size: 12px;
    font-weight: bold;
    color: white;
    border-left: 5px solid white;
    padding-left: 10px;
    margin-bottom: 3px;
    line-height: 1.5;
}

.linkContent {
    margin-left: 15px;
}

.linkContent > ul {
    display: flex;
    list-style: none;
    line-height: 2;
    flex-wrap: wrap;
    margin-bottom: 32px;
}

.linkContent > ul > li {
    margin-right: 5px;
    flex-shrink: 0;
}